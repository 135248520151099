define("discourse/plugins/discourse-siwt/discourse/lib/tezos-client", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/load-script"], function (_exports, _object, _ajax, _ajaxError, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * SPDX-FileCopyrightText: Copyright (C) 2022, vDL Digital Ventures GmbH <info@vdl.digital>
   * SPDX-FileCopyrightText: Copyright (C) 2021, Spruce Systems, Inc.
   *
   * SPDX-License-Identifier: MIT
   */

  const TezosClient = _object.default.extend({
    client: null,
    async init() {
      await this.loadScripts();
      if (this.client) {
        return this.client;
      }
      const beacon = window.beacon;
      const client = new beacon.DAppClient({
        name: "SIWT"
      });
      client.subscribeToEvent(beacon.BeaconEvent.ACTIVE_ACCOUNT_SET, async account => {
        if (!account) {
          return;
        }
      });
      this.client = client;
      return this.client;
    },
    async loadScripts() {
      return Promise.all([(0, _loadScript.default)("/plugins/discourse-siwt/javascripts/beacon.min.js")]);
    },
    async requestAccountInfo() {
      const activeAccount = await this.client.getActiveAccount();
      if (activeAccount) {
        return activeAccount;
      } else {
        const permissions = await this.client.requestPermissions();
        return permissions.accountInfo;
      }
    },
    async signMessage(account) {
      const {
        address,
        network: {
          type: network
        }
      } = account;
      const {
        message
      } = await (0, _ajax.ajax)("/discourse-siwt/message", {
        data: {
          pkh: address,
          chain_id: network
        }
      }).catch(_ajaxError.popupAjaxError);
      const {
        signature
      } = await this.client.requestSignPayload({
        signingType: "micheline",
        payload: message,
        sourceAddress: address
      });
      return {
        signature,
        message
      };
    }
  });
  var _default = _exports.default = TezosClient;
});