define("discourse/plugins/discourse-siwt/discourse/routes/siwt-auth-index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * SPDX-FileCopyrightText: Copyright (C) 2022, vDL Digital Ventures GmbH <info@vdl.digital>
   * SPDX-FileCopyrightText: Copyright (C) 2021, Spruce Systems, Inc.
   *
   * SPDX-License-Identifier: MIT
   */
  var _default = _exports.default = _route.default.extend();
});